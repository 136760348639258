import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { Link } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Logo from '../components/Logo';

import { SecondaryButton } from '../components/Buttons';
import { URLConversion } from '../components/Constants';
import { Item } from '../components/Game/Scoreboard';
import Loading from '../components/Loading';
import Login from '../components/Login';
const auth = getAuth();

const ProfilePage = () => {
	const [user, setUser] = React.useState(auth.currentUser);
	const [displayName, setDisplayName] = React.useState(user?.displayName ?? '');
	const [questionsPlayed, setQuestionsPlayed] = React.useState(0);
	const [allTimeScore, setAllTimeScore] = React.useState(0);
	const [recentlyPlayed, setRecentlyPlayed] = React.useState([]);
	const [highScoreScore, setHighScoreScore] = React.useState(0);
	const [highScoreCategory, setHighScoreCategory] = React.useState('');
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const unregisterAuthObserver = auth.onAuthStateChanged((currentUser) => {
			setUser(currentUser);
		});
		return () => unregisterAuthObserver();
	}, []);

	React.useEffect(() => {
		const asyncFunction = async (uid: string) => {
			const UserDataRef = doc(getFirestore(), `UserData/${uid}`);
			const UserData = await getDoc(UserDataRef);
			if (UserData.exists()) {
				const data = UserData.data();
				if (typeof data !== 'undefined') {
					data.questionsPlayed && setQuestionsPlayed(data.questionsPlayed);
					data.allTimeScore && setAllTimeScore(data.allTimeScore);
					data.recentlyPlayed && setRecentlyPlayed(data.recentlyPlayed);
					data.highScore && setHighScoreScore(data.highScore.score);
					data.highScore && setHighScoreCategory(data.highScore.category);
					data.displayName && setDisplayName(data.displayName);
					setLoading(false);
				}
			}
		};

		if (user) {
			asyncFunction(user.uid);
		} else {
			setQuestionsPlayed(0);
			setAllTimeScore(0);
			setRecentlyPlayed([]);
			setHighScoreScore(0);
			setHighScoreCategory('');
		}
	}, [user]);

	return (
		<>
			<Helmet>
				<title>Google Feud - {user ? 'Profile' : 'Sign In'}</title>
			</Helmet>
			<Logo />
			<h2>{user ? `Profile Page: ${displayName}` : 'Register / Sign In'}</h2>
			{user ? (
				loading ? (
					<Loading />
				) : (
					<>
						<ul>
							<li key="questions">
								<Item
									scoreName={'Total Questions Played'}
									scoreValue={questionsPlayed}
								/>
							</li>
							<li key="points">
								<Item
									highScore
									scoreName={'Total Points'}
									scoreValue={allTimeScore}
								/>
							</li>
							<li key="highscore">
								<Item
									scoreName={`High Score${
										highScoreCategory && ` (${highScoreCategory})`
									}`}
									scoreValue={highScoreScore}
								/>
							</li>
							{recentlyPlayed.length > 0 && (
								<li key="recent">
									<Item
										scoreName={'Recently Played Questions'}
										scoreValue={
											<ul style={{ marginTop: 'calc(-1 * var(--half))' }}>
												{recentlyPlayed.slice(-10).map((question) => (
													<li
														key={question}
														className="altList"
														style={{
															margin: 'var(--half)',
														}}>
														<Link to={`../answers/${URLConversion(question)}/`}>
															{question}
														</Link>
													</li>
												))}
											</ul>
										}
									/>
								</li>
							)}
						</ul>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<SecondaryButton onClick={() => auth.signOut()} text="Sign Out" />
						</div>
					</>
				)
			) : (
				<>
					<p>
						Sign in to Google Feud to keep track of played questions and high
						scores, see answers and more!
					</p>
					<Login />
				</>
			)}
		</>
	);
};
export default ProfilePage;
